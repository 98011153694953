.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}
.rbt .rbt-input-main::-ms-clear {
  display: none;
}

/**
 * Menu
 */
.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

/**
 * Multi-select Input
 */
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
}

.rbt-input-multi.focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}

.rbt-input-multi.form-control {
  height: auto;
}

.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 24px;
}

/**
 * Token
 */
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: .25rem;
  color: #007bff;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;
}

.rbt-token-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: #495057;
  pointer-events: none;
}

.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.rbt-token-active {
  background-color: #007bff;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}

.rbt-aux-lg {
  width: 46px;
}

.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
  flex: 1 1;
}

.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.month-picker {
  position: relative; }
  .month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .rmp-container.rmp-table {
      display: table; }
    .month-picker > .rmp-container.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .rmp-container.show .rmp-overlay {
        left: 0; }
    .month-picker > .rmp-container .rmp-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .rmp-container .rmp-cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-cell {
          vertical-align: bottom; } }
    .month-picker > .rmp-container .rmp-popup {
      position: absolute;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .rmp-container .rmp-popup.range {
            height: 28rem;
            transform: translate3d(0, 28rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
              margin-top: 0.4rem; }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .rmp-container .rmp-popup {
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .rmp-container .rmp-popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .rmp-container .rmp-popup.show {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .month-picker > .rmp-container .rmp-popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .rmp-container .rmp-popup .rmp-pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
            left: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
            right: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
          display: block;
          width: 100%; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          display: block;
          float: left;
          text-align: center;
          font-size: 1.15rem;
          border-radius: 3px;
          line-height: 3.3rem;
          box-sizing: border-box;
          padding: 0.05rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 33.3333333333%; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
            background-clip: content-box; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
            border-radius: 1px; }
      .month-picker > .rmp-container .rmp-popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
            background-color: #d3d3d3; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .rmp-container .rmp-popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
          cursor: pointer;
          moz-user-select: -moz-none;
          -moz-user-select: none;
          -o-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
            background-color: #262828; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

